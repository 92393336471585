<template>
  <div class="app-container">
    <el-tabs v-model="activeNames" @tab-click="handleClick" v-loading="loading">
      <el-tab-pane label="User LDB score" name="1">
        <el-form ref="form" :model="userLDBScoreForm" label-width="200px">
          <el-form-item label="User ID">
            <el-input
              v-model="userLDBScoreForm.userID"
              @change="handleUserIDChange"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="onSearchUserByUserID"
              >Search</el-button
            >
          </el-form-item>

          <div v-if="hasFetchedData">
            <el-form-item label="Event ID">
              <el-select
                v-model="form.region"
                placeholder="Please select an event"
                style="width: 100%"
                @change="handleEventIDSelect"
              >
                <el-option
                  v-for="(eventScoreInfo, index) in this.userFullScoreData"
                  :key="index"
                  :label="eventScoreInfo.eventID"
                  :value="eventScoreInfo.eventID"
                ></el-option>
              </el-select>
            </el-form-item>

            <div v-if="this.currentSelectedEventID">
              <strong>Total score: </strong>
              {{ this.currentSelectedEventScoreInfo.fullscore }}

              <el-table
                :data="this.currentSelectedEventScoreInfo.logs"
                stripe
                height="500"
                sortable
              >
                <el-table-column prop="score" label="Score"> </el-table-column>
                <el-table-column prop="timestamp" label="Timestamp">
                </el-table-column>
                <el-table-column prop="dateTime" label="Date">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { Message } from "element-ui";
import { isStringEmpty } from "@/utils/validate";

import apiClanLDB from "@/api/apiClanLDB";
import { getEventDataFromFirebase } from "@/api/dynamic_events";

export default {
  name: "LDBScores",
  components: {},
  data() {
    return {
      form: {
        displayName: "",
        codeName: "",
        desc: "",
      },
      userLDBScoreForm: {
        userID: "Aus7Df67cxN",
      },
      loading: false,
      activeNames: "1",
      eventConfigData: [],
      userFullScoreData: [
        {
          eventID: "lbq_101",
          logs: [
            {
              score: 1,
              timestamp: 1,
            },
          ],
          fullScore: 100,
        },
      ],
      hasFetchedData: false,
      currentSelectedEventID: "",
      currentSelectedEventScoreInfo: null,
    };
  },
  created() {
    this.onCreatedGetEventConfigData();
  },
  methods: {
    onCreatedGetEventConfigData() {
      const action = async () => {
        this.loading = true;

        try {
          const response = await getEventDataFromFirebase();

          this.loading = false;

          if (!response || !response.events) {
            throw new Error(
              "🚀 onCreatedGetEventConfigData ~ error: Null response"
            );
          }

          this.eventConfigData = response.events;
        } catch (error) {
          this.loading = false;
          console.log("🚀 onCreatedGetEventConfigData ~ error:", error);
          Message({
            message: "Error while getting event config data",
            type: "error",
            duration: 5 * 1000,
          });
        }
      };

      action();
    },
    //---------------------------------------------------------
    async onSearchUserByUserID() {
      if (isStringEmpty(this.userLDBScoreForm.userID)) {
        Message({
          message: "User ID is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      this.loading = true;

      const userID = this.userLDBScoreForm?.userID;

      try {
        const response = await apiClanLDB.getUserScoreFullData(userID);

        this.loading = false;

        if (!response || !response.data) {
          Message({
            message: "Network error :)",
            type: "error",
            duration: 5 * 1000,
          });
        }

        if (!response || !response.data) {
          Message({
            message: "Network error :)",
            type: "error",
            duration: 5 * 1000,
          });
        }

        const keys = Object.keys(response.data);

        const eventKeys = keys.filter(
          (key) => !key.includes("Log") && !key.toLowerCase().includes("id")
        );

        this.userFullScoreData = eventKeys.map((eventKey) => {
          const configData = this.eventConfigData.find((configData) => {
            const id = eventKey.match(/\d+/);
            return configData["event_id"] === id.toLocaleString();
          });

          const eventName =
            configData && configData["event_name"]
              ? configData["event_name"]
              : "NO NAME";

          return {
            eventID: eventName + " - " + eventKey,
            logs: response.data[`${eventKey}Log`],
            fullscore: response.data[`${eventKey}`],
          };
        });

        this.userFullScoreData.reverse();

        this.hasFetchedData = this.userFullScoreData.length > 0;
      } catch (error) {
        this.loading = false;

        Message({
          message: "Hỏng lấy được user :(" + error,
          type: "error",
          duration: 5 * 1000,
        });
      }
    },
    handleUserIDChange(userID) {
      this.hasFetchedData = false;
    },
    handleClick() {},
    handleEventIDSelect(eventID) {
      this.currentSelectedEventID = eventID;
      this.currentSelectedEventScoreInfo = this.userFullScoreData.find(
        (scoreData) => scoreData.eventID === eventID
      );

      this.currentSelectedEventScoreInfo.logs =
        this.currentSelectedEventScoreInfo.logs.map((log) => ({
          ...log,
          dateTime: new Date(log.timestamp * 1000).toLocaleString(),
        }));

      const configData = this.eventConfigData.find((configData) => {
        const id = eventID.match(/\d+/);
        return configData["event_id"] === id.toLocaleString();
      });

      this.currentSelectedEventScoreInfo.eventID =
        this.currentSelectedEventScoreInfo.eventID +
        " " +
        configData["event_name"];

      console.log("adakjdflajdf ", this.currentSelectedEventScoreInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
.userprofile-container {
  margin: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
</style>
